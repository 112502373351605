import { useCallback, useEffect, useState } from "react";
import { CONTRACT_OVERNIGHT_HOURS, CONTRACT_PRESENCE_HOURS, TGetContractsCollectionItem } from "../../../../../api/contract/contract.types";
import useContractToSelectedStateDialogFormContext from "../hooks/useContractToSelectedStateDialogFormContext";
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../containers/themes/defaultTheme";
import { FormattedMessage } from "react-intl";
import { FormikSelect, FormikTextField } from "../../../../../components/FormikFields";
import { getIn } from "formik";
import FormikDatePicker from "../../../../../components/FormikFields/FormikDatePicker";
import { YELLOW, YELLOW_WITH_OPACITY } from "../../../../../constants/colors";
import WarningIcon from '@material-ui/icons/Warning';
import { isContractOriginWarrantyZeroOrChangeEmployer } from "../../../../../util/Contrato/util";

export const ContractToSelectedStateDialogHeader = styled(Typography)`
  margin: 1.25rem 2rem;
  text-align: start;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const HighlightedText = styled.span`
background-color: ${YELLOW};
border-radius: 4px;
padding: 0.2rem 0.4rem;
text-transform: uppercase;
font-weight: bold;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  margin-right: 1rem;
`;

export const ContractToSelectedStateDialogContent = styled(Typography)`
  margin: 1rem 2rem;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.5rem;
`;

export const ContractCustomerNotDefinedWarning = styled(Typography)`
  display: flex;
  align-items: center;
  background-color: rgba(255, 235, 59, 0.1);
  color: ${YELLOW};
  border-radius: 4px;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid ${YELLOW_WITH_OPACITY};
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

export const WarningIconWrapper = styled.div`
  margin-right: 0.5rem;
  color: ${YELLOW};
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

export const StyledCancelButton = styled(Button)``;

export const StyledConfirmButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

interface IContractToSelectedStateDialogInnerFormProps {
  contract: TGetContractsCollectionItem,
  isRequestLoading: boolean,
  onClose: () => void
}

const ContractToSelectedStateDialogInnerForm = (
  props: IContractToSelectedStateDialogInnerFormProps
) => {
  const { contract, isRequestLoading, onClose } = props;
  const { formikContext, formOptions, formActions } = useContractToSelectedStateDialogFormContext();

  const [showOvernightNights, setShowOvernightNights] = useState<boolean>(false);
  const [showNumPresenceHours, setShowNumPresenceHours] = useState<boolean>(false);
  const [customerEmployer, setCustomerEmployer] = useState(undefined as any);
  const [showNotCompletedCustomerEmployerWarning, setShowNotCompletedCustomerEmployerWarning] = useState<boolean>(false);

  const getContractCustomerEmployer = useCallback(
    async (params: any) => {
      try {
        const employer = await formActions.getCustomerNewestEmployer(contract);
        if (employer) {
          setCustomerEmployer(employer);
          setShowNotCompletedCustomerEmployerWarning(!employer.completed);
        } else {
          setCustomerEmployer(null);
          setShowNotCompletedCustomerEmployerWarning(true);
        }
      } catch (e:any) {
        setCustomerEmployer(null);
        if (e instanceof Error && e.message && e.message.includes('Employer not found')) {
          setShowNotCompletedCustomerEmployerWarning(false);
        } else {
          setShowNotCompletedCustomerEmployerWarning(true);
        }
      }
    },
    [formActions, contract]
  );

  useEffect(() => {
    setShowOvernightNights(getIn(formikContext.values, "Horas_de_pernocta")?.value === CONTRACT_OVERNIGHT_HOURS.YES);
    setShowNumPresenceHours(getIn(formikContext.values, "Horas_de_presencia")?.value   === CONTRACT_PRESENCE_HOURS.YES);
    if (customerEmployer === undefined) {
      setCustomerEmployer(null);
      getContractCustomerEmployer({});
    }
  }, [formikContext, contract, customerEmployer, getContractCustomerEmployer]);

  const handleClose = () => {
    onClose();
  };

  const isOriginWarrantyZeroOrChangeEmployer = isContractOriginWarrantyZeroOrChangeEmployer(contract);

  return (
    <>
      <ContractToSelectedStateDialogHeader>
        {contract.Name}
        &nbsp;&nbsp;-&nbsp;&nbsp;
        <HighlightedText>
          <FormattedMessage
            id="Seleccionado"
            defaultMessage={"Seleccionado"}
          />
        </HighlightedText>
        <CloseButton onClick={handleClose}>x</CloseButton>
      </ContractToSelectedStateDialogHeader>
      <hr />
      {(showNotCompletedCustomerEmployerWarning) && (
        <ContractCustomerNotDefinedWarning>
          <WarningIconWrapper>
            <WarningIcon />
          </WarningIconWrapper>
          {showNotCompletedCustomerEmployerWarning && (
            <FormattedMessage
              id="ContractToSelectedStateDialog.Falta completar el formulario del empleador"
              defaultMessage={"Falta completar el formulario del empleador"}
            />
          )}
        </ContractCustomerNotDefinedWarning>
      )}
      <ContractToSelectedStateDialogContent>
        <FormattedMessage
          id="ContractToSelectedStateDialog.Por favor, rellena los datos necesarios para poder activar el contrato"
          defaultMessage={"Por favor, rellena los datos necesarios para poder activar el contrato"}
        />{": "}
      </ContractToSelectedStateDialogContent>

      <Box m={10}>
        <Grid container spacing={2} style={{ margin: 16 }}>

          {/* First Column */}
          <Grid item xs={12} sm={6}>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Tipo de contrato"
                    defaultMessage={"Tipo de contrato"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  name="Tipo_de_contrato"
                  required
                  size="small"
                  margin="nomargin"
                  variant="compact"
                  options={formOptions.contractTypeOptions}
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Horario de servicio"
                    defaultMessage={"Horario de servicio"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  name="Horario_Servicio"
                  required
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Horas jornada semanal"
                    defaultMessage={"Horas jornada semanal"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  name="Horas_jornada_semanal"
                  required
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Horas de pernocta"
                    defaultMessage={"Horas de pernocta"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  name="Horas_de_pernocta"
                  required
                  size="small"
                  margin="nomargin"
                  variant="compact"
                  options={formOptions.contractOvernightHoursOptions}
                />
              </Grid>
            </Grid>

            {showOvernightNights && (
              <Grid container spacing={10}>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="ContractToSelectedStateDialog.Noches de pernocta"
                      defaultMessage={"Noches de pernocta"}
                    /> {`*`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormikSelect
                    name="Noches_de_pernocta"
                    required
                    size="small"
                    margin="nomargin"
                    variant="compact"
                    options={formOptions.contractOvernightNightsOptions}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Horas de presencia"
                    defaultMessage={"Horas de presencia"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  name="Horas_de_presencia"
                  required
                  size="small"
                  margin="nomargin"
                  variant="compact"
                  options={formOptions.contractPresenceHoursOptions}
                />
              </Grid>
            </Grid>

            {showNumPresenceHours && (
              <Grid container spacing={10}>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="ContractToSelectedStateDialog.Nº de horas de presencia a la semana"
                      defaultMessage={"Nº de horas de presencia a la semana"}
                    /> {`*`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField
                    name="N_de_horas_de_presencia_a_la_semana"
                    required
                    fullWidth
                    size="small"
                    margin="nomargin"
                  />
                </Grid>
              </Grid>
            )}

          {/* End First Column */}
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} sm={6}>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Fecha inicio real"
                    defaultMessage={"Fecha inicio real"}
                  /> {!isOriginWarrantyZeroOrChangeEmployer && (`*`)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  name="Fecha_de_inicio_real"
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Fecha fin contrato"
                    defaultMessage={"Fecha fin contrato"}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  name="Fecha_fin_contrato"
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Período de prueba"
                    defaultMessage={"Período de prueba"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  name="Per_odo_de_prueba"
                  required
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Tipo de salario"
                    defaultMessage={"Tipo de salario"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  name="Tipo_de_salario"
                  required
                  size="small"
                  margin="nomargin"
                  variant="compact"
                  options={formOptions.contractSalaryTypeOptions}
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Número de pagas"
                    defaultMessage={"Número de pagas"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  name="Numero_de_pagas"
                  required
                  size="small"
                  margin="nomargin"
                  variant="compact"
                  options={formOptions.contractNumPaymentsOptions}
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Salario bruto"
                    defaultMessage={"Salario bruto"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  name="Salario_Bruto_Mensual"
                  required
                  fullWidth
                  size="small"
                  margin="nomargin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ContractToSelectedStateDialog.Cláusulas especiales"
                    defaultMessage={"Cláusulas especiales"}
                  /> {`*`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  name="Cl_usulas_especiales"
                  required
                  fullWidth
                  size="small"
                  margin="nomargin"
                  multiline
                  maxRows="10"
                  minRows="3"
                />
              </Grid>
            </Grid>

            {isOriginWarrantyZeroOrChangeEmployer && (
              <Grid container spacing={10}>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="ContractToSelectedStateDialog.Notas asesora selección"
                      defaultMessage={"Notas asesora selección"}
                    /> {`*`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField
                    name="Notas_Asesora_Seleccion"
                    required
                    fullWidth
                    size="small"
                    margin="nomargin"
                    multiline
                    maxRows="10"
                    minRows="3"
                  />
                </Grid>
              </Grid>
            )}

          {/* End Second Column */}
          </Grid>

        </Grid>
      </Box>


      <Box marginTop={10} marginBottom={10}>
        <StyledActions>
          <StyledConfirmButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={isRequestLoading}
            startIcon={
              isRequestLoading ? (
                <CircularProgress thickness={5} size={16} />
              ) : undefined
            }
          >
            <FormattedMessage
              id="ContractToSelectedStateDialog.Cambiar a Seleccionado"
              defaultMessage="Cambiar a Seleccionado"
            />
          </StyledConfirmButton>
        </StyledActions>
      </Box>
    </>
  );
};

export default ContractToSelectedStateDialogInnerForm;
